import { IdentityAppName } from '../constants/constants';
import { DemoAppBar } from './DemoAppBar';
import { AppShell } from '../components/app-shell/AppShell';
import { Outlet } from 'react-router-dom';
import { usePackageVersionContext } from '../components/version-selector/PackageVersionContext';
import { RccContextConfig } from 'rcc-ga';
import { RccEnvironment } from '@ncr-voyix-commerce/react-common-components';

const rccConfig: RccContextConfig = {
    language: 'en',
    environment: window._env_.REACT_APP_ENVIRONMENT as unknown as RccEnvironment,
    _TEMPORARY_globalProfileOktaSdkEnabled: false,
};

const AppLayout = () => {
    const { module } = usePackageVersionContext();

    if (!module) {
        return <div>Loading...</div>;
    }

    const { RccContextProvider, ApplicationsContextProvider } = module;

    return (
        <RccContextProvider config={rccConfig}>
            <ApplicationsContextProvider defaultApp={IdentityAppName}>
                <DemoAppBar />
                <AppShell>
                    <Outlet />
                </AppShell>
            </ApplicationsContextProvider>
        </RccContextProvider>
    );
};

export default AppLayout;
