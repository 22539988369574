import { OktaAuthOptions } from '@okta/okta-auth-js';

const getOktaCustomDomain = (): string => {
    return window._env_.REACT_APP_OKTA_CUSTOM_DOMAIN!;
};

const getOktaClientId = (): string => {
    return window._env_.REACT_APP_OKTA_CLIENT_ID!;
};

export const oktaAuthOptions: OktaAuthOptions = {
    clientId: getOktaClientId(),
    scopes: [
        'openid',
        'profile',
        'email',
        'okta.myAccount.email.manage',
        'okta.myAccount.profile.manage',
    ],
    pkce: true,
    redirectUri: `${window.location.origin}/login/callback`,
    issuer: `https://${getOktaCustomDomain()}/oauth2/default`,
};
