import { AppBar, Box, Stack, Theme } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import useMobileView from '../hooks/useMobileView';
import { DemoSidenav } from '../components/navigation/sidenav/Sidenav';
import { LoginButton } from '../components/navigation/login-button/LoginButton';
import { usePackageVersionContext } from '../components/version-selector/PackageVersionContext';

export const DemoAppBar = () => {
    const { authState } = useOktaAuth();
    const isMobileView = useMobileView();
    const [isSidenavOpen, setIsSidenavOpen] = useState(false);

    const { module } = usePackageVersionContext();

    const { OrganizationSwitcher, ApplicationSwitcher, UserProfileSettings } = module;

    const toggleSidenav = (isOpen: boolean) => {
        setIsSidenavOpen(isOpen);
    };

    return (
        <AppBar
            position='static'
            sx={(theme: Theme) => ({
                px: 'var(--app-padding-side)',
                py: '8px',
                top: 0,
                boxShadow: 'none',
                left: 0,
                justifyContent: 'space-between',
                color: theme.palette.text.primary,
                rowGap: 1,
                columnGap: 2,
                bgcolor: theme.palette.background.default,
                backgroundImage: 'none',
                borderBottom: `1px solid ${theme.palette.divider}`,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            })}
        >
            <Box marginLeft='-6px'>
                <ApplicationSwitcher
                    onClick={() => {
                        if (isMobileView) {
                            toggleSidenav(true);
                        }
                    }}
                    isDisplayMode={isMobileView}
                />
            </Box>

            <DemoSidenav open={isSidenavOpen} onClose={() => toggleSidenav(false)} />

            {authState?.isAuthenticated ? (
                <Stack direction='row' alignItems='center' columnGap='16px'>
                    <OrganizationSwitcher />
                    <UserProfileSettings
                        inputOptions={[
                            {
                                label: 'Home',
                                url: '/app/home',
                                icon: <HomeIcon />,
                            },
                        ]}
                    />
                </Stack>
            ) : (
                <LoginButton />
            )}
        </AppBar>
    );
};
