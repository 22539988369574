import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createContext, ReactNode, useMemo, useState } from 'react';
import { createTheme } from '@mui/material';
import { getDesignTokens } from '../../theme/theme-util';

type IThemeContext = {
    setColorMode: (value: 'light' | 'dark') => void;
    toggleColorMode: () => void;
};

export const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [mode, setMode] = useState<'light' | 'dark'>(prefersDarkMode ? 'dark' : 'light');

    const theme = useMemo(() => {
        return createTheme(getDesignTokens(mode));
    }, [mode]);

    const colorMode = useMemo(
        () => ({
            setColorMode: (newMode: 'light' | 'dark') => {
                setMode(newMode);
            },
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    return (
        <ThemeContext.Provider value={colorMode}>
            <MuiTheme theme={theme}>
                <CssBaseline />
                {children}
            </MuiTheme>
        </ThemeContext.Provider>
    );
};
