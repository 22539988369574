import GitHubIcon from '@mui/icons-material/GitHub';
import { Button, Link, Stack } from '@mui/material';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { Outlet, useNavigate } from 'react-router-dom';
import './App.css';
import MockBrowserContainer from './components/mock-browser-container/MockBrowserContainer';
import { ThemeProvider } from './components/providers/ThemeProvider';
import { PackageVersionProvider } from './components/version-selector/PackageVersionContext';
import { PackageVersionSelector } from './components/version-selector/PackageVersionSelector';
import { RccRepoUrl } from './constants/constants';
import { oktaAuthOptions } from './constants/okta-auth-config';

const oktaAuth = new OktaAuth(oktaAuthOptions);

function App() {
    const navigate = useNavigate();
    const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
        navigate(toRelativeUrl(originalUri || '', window.location.origin), {
            replace: true,
        });
    };

    return (
        <div className='App'>
            <ThemeProvider>
                <Security restoreOriginalUri={restoreOriginalUri} oktaAuth={oktaAuth}>
                    <PackageVersionProvider>
                        <Hero />
                        <MockBrowserContainer>
                            <Outlet />
                        </MockBrowserContainer>
                    </PackageVersionProvider>
                </Security>
            </ThemeProvider>
        </div>
    );
}

const Hero = () => {
    return (
        <Stack id='preface' alignItems='center'>
            <h1>
                Reference App for the{' '}
                <Link target='_blank' href={RccRepoUrl}>
                    React Common Components
                </Link>
            </h1>
            <Stack direction='row' gap='16px'>
                <PackageVersionSelector />
                <Button
                    href='/storybook/'
                    target='_blank'
                    variant='contained'
                    sx={{ textTransform: 'none' }}
                >
                    Storybook
                </Button>
                <Button
                    href={RccRepoUrl}
                    target='_blank'
                    variant='contained'
                    sx={{ textTransform: 'none' }}
                    startIcon={<GitHubIcon />}
                >
                    Documentation
                </Button>
            </Stack>
        </Stack>
    );
};

export default App;
