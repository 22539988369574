import {
    createContext,
    ReactNode,
    Suspense,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import packageFile from '../../../package.json';

export type PackageVersion = 'rcc-beta' | 'rcc-alpha' | 'rcc-ga';

const extractVersionNumber = (input: string): string => {
    const regex = /(\d+\.\d+\.\d+)/;
    const match = input.match(regex);
    return match ? match[1] : 'unknown';
};

interface IPackageVersionContext {
    packageVersion: PackageVersion;
    getSemver: (version: PackageVersion) => string;
    setPackageVersion: (packageVersion: PackageVersion) => void;
    module?: any;
}

export const PackageVersionContext = createContext<IPackageVersionContext>({
    packageVersion: 'rcc-ga',
    getSemver: () => 'unknown',
    setPackageVersion: () => {},
    module: undefined,
});

export const PackageVersionProvider = ({ children }: { children: ReactNode }) => {
    const [packageVersion, setPackageVersion] = useState<PackageVersion>('rcc-ga');
    const [module, setModule] = useState<any>();

    const getSemver = (version: PackageVersion) => {
        return extractVersionNumber(packageFile.dependencies[version]);
    };

    useEffect(() => {
        const loadModule = async () => {
            if (packageVersion === 'rcc-ga') {
                const mod = await import('rcc-ga');
                setModule(mod);
            } else if (packageVersion === 'rcc-beta') {
                const mod = await import('rcc-beta');
                setModule(mod);
            } else if (packageVersion === 'rcc-alpha') {
                const mod = await import('rcc-alpha');
                setModule(mod);
            }
        };

        (async () => loadModule())();
    }, [packageVersion]);

    const context = useMemo(
        () => ({
            packageVersion: packageVersion,
            getSemver: getSemver,
            setPackageVersion,
            module,
        }),
        [packageVersion, getSemver, setPackageVersion, module],
    );

    return (
        <PackageVersionContext.Provider value={context}>
            <Suspense>{children}</Suspense>
        </PackageVersionContext.Provider>
    );
};

export const usePackageVersionContext = () => useContext(PackageVersionContext);
