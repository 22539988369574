import { useOktaAuth } from '@okta/okta-react';
import { Navigate, Outlet } from 'react-router-dom';

export const AuthenticationGuard = () => {
    const { authState } = useOktaAuth();

    if (authState && !authState.isAuthenticated) {
        return <Navigate to='/' replace={true} />;
    }

    return <Outlet />;
};
