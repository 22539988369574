import { LoginCallback } from '@okta/okta-react';
import { Route, createRoutesFromElements } from 'react-router-dom';
import { HomePage } from '../../pages/HomePage';
import Loading from '../loading/Loading';
import { NavigateWithParams } from '../navigation/navigate/NavigateWithParams';
import { AuthenticationGuard } from '../navigation/router-guards/AuthenticationGuard';
import { LandingPage } from '../../pages/LandingPage';
import App from '../../App';
import AppLayout from '../../pages/AppLayout';

export const createAppRoutes = () =>
    createRoutesFromElements(
        <Route element={<App />}>
            <Route element={<AppLayout />}>
                <Route index element={<LandingPage />} />,
                <Route element={<AuthenticationGuard />}>
                    <Route path='app/home' element={<HomePage />} />
                    <Route path='*' element={<NavigateWithParams to='app/home' />} />
                </Route>
            </Route>
            <Route path='login/callback' element={<LoginCallback loadingElement={<Loading />} />} />
            <Route path='*' element={<div>Not Found</div>} />
        </Route>,
    );
