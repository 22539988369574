import { useContext, useEffect } from 'react';
import { ThemeContext } from '../components/providers/ThemeProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { usePackageVersionContext } from '../components/version-selector/PackageVersionContext';

export const useConfigureUserPreferences = () => {
    const { module } = usePackageVersionContext();
    const { userPreferences } = module.useUserPreferencesContext();

    const { setColorMode } = useContext(ThemeContext);
    const systemPrefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    useEffect(() => {
        if (!userPreferences?.theme || userPreferences?.theme === 'system') {
            setColorMode(systemPrefersDarkMode ? 'dark' : 'light');
        } else {
            setColorMode(userPreferences.theme);
        }
    }, [userPreferences?.theme, systemPrefersDarkMode, setColorMode]);
};
