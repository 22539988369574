import { Box, Theme } from '@mui/material';
import { useConfigureUserPreferences } from '../../hooks/useConfigureUserPreferences';
import { ReactNode } from 'react';

export const AppShell = ({ children }: { children: ReactNode }) => {
    useConfigureUserPreferences();

    return (
        <Box
            height='100%'
            px='var(--app-padding-side)'
            paddingTop='var(--app-padding-header)'
            paddingBottom='var(--app-padding-footer)'
            sx={(theme: Theme) => ({
                backgroundColor: theme.palette.background.default,
            })}
        >
            {children}
        </Box>
    );
};
