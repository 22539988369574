import { PropsWithChildren } from 'react';
import { Paper } from '@mui/material';
import styles from './MockBrowserContainer.module.css';

const MockBrowserContainer = ({ children }: PropsWithChildren) => {
    return (
        <Paper elevation={2} sx={{ margin: '64px' }} className={styles.container}>
            <div className={styles.row}>
                <div className={`${styles.column} ${styles.left}`}>
                    <span className={`${styles.dot} ${styles.close}`} />
                    <span className={`${styles.dot} ${styles.min}`} />
                    <span className={`${styles.dot} ${styles.max}`} />
                </div>
                <div className={`${styles.column} ${styles.middle}`}>
                    <input
                        className={styles.searchBar}
                        type='text'
                        value={window.location.toString()}
                        disabled
                    />
                </div>
                <div className={`${styles.column} ${styles.right}`}>
                    <div style={{ float: 'right' }}>
                        <span className={styles.bar} />
                        <span className={styles.bar} />
                        <span className={styles.bar} />
                    </div>
                </div>
            </div>
            <div className={styles.content}>{children}</div>
        </Paper>
    );
};

export default MockBrowserContainer;
