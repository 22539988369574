import { PropsWithChildren } from 'react';
import { PackageVersion, usePackageVersionContext } from './PackageVersionContext';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import packageFile from '../../../package.json';

interface PackageVersionMapItem {
    label: string;
    packageVersion: PackageVersion;
    semver: string;
}

const extractVersionPin = (input: string): string => {
    const regex = /@([^@]+)$/;
    const match = input.match(regex);
    return match ? match[1] : 'unknown';
};

interface PackageVersionSelectorProps {
    versions?: PackageVersion[];
}

export const PackageVersionInfo: PackageVersionMapItem[] = [
    {
        label: 'Generally Available',
        packageVersion: 'rcc-ga' as PackageVersion,
        semver: extractVersionPin(packageFile.dependencies['rcc-ga']),
    },
    {
        label: 'Beta',
        packageVersion: 'rcc-beta' as PackageVersion,
        semver: extractVersionPin(packageFile.dependencies['rcc-beta']),
    },
    {
        label: 'Alpha',
        packageVersion: 'rcc-alpha' as PackageVersion,
        semver: extractVersionPin(packageFile.dependencies['rcc-alpha']),
    },
];

export const PackageVersionSelector = ({
    versions = ['rcc-alpha', 'rcc-beta', 'rcc-ga'],
}: PropsWithChildren<PackageVersionSelectorProps>) => {
    const { packageVersion, setPackageVersion } = usePackageVersionContext();

    const supportedVersionsMap = PackageVersionInfo.filter((version) =>
        versions.some((v) => v === version.packageVersion),
    );

    const handleChange = (event: SelectChangeEvent) => {
        setPackageVersion(event.target.value as PackageVersion);
    };

    return (
        <Select value={packageVersion} onChange={handleChange}>
            {supportedVersionsMap.map((version) => (
                <MenuItem key={version.packageVersion} value={version.packageVersion}>
                    {version.label} ({version.semver})
                </MenuItem>
            ))}
        </Select>
    );
};
