import { Drawer, DrawerProps, Stack } from '@mui/material';
import { usePackageVersionContext } from '../../version-selector/PackageVersionContext';

export const DemoSidenav = (props: Omit<DrawerProps, 'onClose'> & { onClose: () => void }) => {
    const { onClose, ...drawerProps } = props;

    const { module } = usePackageVersionContext();

    return (
        <Drawer onClose={onClose} {...drawerProps}>
            <Stack py='8px' px='var(--app-padding-side)'>
                <module.ApplicationSwitcher onClose={onClose} />
            </Stack>
        </Drawer>
    );
};
