import { Box, Typography } from '@mui/material';

export const LandingPage = () => {
    return (
        <Box>
            <Typography variant='h5' maxWidth={'400px'}>
                Welcome to the React Common Component Reference App!
            </Typography>
        </Box>
    );
};
